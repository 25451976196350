import React, { Component, useState } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import Shop from '../components/Shop';
import ShopDetail from '../components/ShopDetail';


interface State {
   index : number
}


class ShopDetailPage extends Component<{}, State> {

  constructor(props: {}) {
    super(props);
    this.state = {
      index : 0
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

 
   render(): React.ReactNode {
     return(
        <div className="cmd-page">
            <Header />
            <Breadcrumb breadcrumb="Detail" />

            <ShopDetail />

            <Footer />
        </div>
    )
  };
};

export default ShopDetailPage;
