import React, { Component, useState } from 'react';
import contentService from '../services/content.service';
import { baseUrl } from '../services/apiUrl';
import { Spinner } from 'react-bootstrap';


interface State {
   index : number,
   heroes : any[],
}


class Hero extends Component<any, any> {

  constructor(props:any) {
    super(props);
    this.state = {
      index : 0,
      heroes : [],
      loaded : false,
    };
  }

  componentDidMount(){
      
    this.getHeroes();

  }
 
  getHeroes()
  {
    contentService.getHeroes()
    .then((d:any)=>{

        var heroes = d;
        this.setState({heroes : heroes, loaded : true})

    })
    .catch(e=>{
        this.setState({loaded : true})
      console.log(e)
    })
  }



   render(): React.ReactNode {
     return(
        <div className="container-fluid mb-3">
        <div className="row px-xl-5">
            {!this.state.loaded &&

            <div className='data-preloader'>
                <Spinner animation="border" role="status"></Spinner>
                <span>Chargement...</span>
            </div>
            }
            {this.state.loaded &&
            <div className="col-lg-8">
                <div id="header-carousel" className="carousel slide carousel-fade mb-30 mb-lg-0" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#header-carousel" data-slide-to="0" className="active"></li>
                        <li data-target="#header-carousel" data-slide-to="1"></li>
                        <li data-target="#header-carousel" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        
                        {this.state.heroes.map((item:any,index:number)=>{
                            
                            return <div key={index} className={`carousel-item position-relative ${index == 0 ? "active" : ""} `} style={{height: 430}}>
                                <img className="position-absolute w-100 h-100" src={`${baseUrl}${item.image.url}`} style={{objectFit: 'cover'}} />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{maxWidth: 700}}>
                                        <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">{item.titre}</h1>
                                        <p className="mx-md-5 px-5 animate__animated animate__bounceIn">{item.description}</p>
                                    </div>
                                </div>
                            </div>
                            })
                       
                        }
                        
                    </div>
                </div>
            </div>
            }
            <div className="col-lg-4">
                
                {this.props.banners.map((item:any,index:number)=>{
                
                    return <div key={index} className="product-offer mb-30" style={{height: 200}}>
                        <img className="img-fluid" src={`${baseUrl}${item.image.url}`} alt="" />
                        <div className="offer-text">
                            <h6 className="text-white text-uppercase">{item.titre}</h6>
                            {/* <h3 className="text-white mb-3">{item.description}</h3> */}
                            {/* <a href="" className="btn btn-primary">Shop Now</a> */}
                        </div>
                    </div>
                    })
                
                }
                 
            </div>
        </div>
    </div>
    )
  };
};

export default Hero;
