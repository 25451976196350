import React, { Component, useState } from 'react';
import { baseUrl } from '../services/apiUrl';
import { Spinner } from 'react-bootstrap';


interface State {
   index : number,
   categories : any[]
}


class CategoriesCards extends Component<any, State> {

  constructor(props: {}) {
    super(props);
    this.state = {
      index : 0,
      categories : []
    };
  }

   render(): React.ReactNode {
     return(
        <div className="container-fluid pt-5">
        <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="bg-secondary pr-3">Categories</span></h2>
        <div className="row px-xl-5 pb-3">
            
            {!this.props.loaded &&
              <div className='data-preloader'>
                <Spinner animation="border" role="status"></Spinner>
                <span>Chargement...</span>
              </div>
            }
            {this.props.categories.map((item:any, index:number)=>{
                return <div key={index} className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <a className="text-decoration-none" href={`/shop/categories/${item.id}`}>
                    <div className="cat-item d-flex align-items-center mb-4">
                        <div className="overflow-hidden" style={{width: 100, height: 100}}>
                            <img className="img-fluid"  src={`${baseUrl}${item.image.url}`} alt="" />
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>{item.titre}</h6>
                            {/* <small className="text-body">100 Products</small> */}
                        </div>
                    </div>
                </a>
            </div>
            })
            
            }
          
        </div>
    </div>
    )
  };
};

export default CategoriesCards;
