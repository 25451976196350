import React, { Component, useState } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import Shop from '../components/Shop';


interface State {
   index : number
}


class ShopPage extends Component<{}, State> {

  constructor(props: {}) {
    super(props);
    this.state = {
      index : 0
    };
  }

 
   render(): React.ReactNode {
     return(
        <div className="cmd-page">
            <Header />
            <Breadcrumb breadcrumb="Shop"/>

            <Shop />

            <Footer />
        </div>
    )
  };
};

export default ShopPage;
