
import React, { Component, useState } from 'react';
import { withRouter } from './WithRouterProps';
import { connect } from 'react-redux';
import { bindActionCreators} from 'redux';
import * as mainActions from '../redux/main/mainActions'
import contentService from '../services/content.service';
import { baseUrl } from '../services/apiUrl';

interface State {
   index : number,
   productsCount : number,
   page : number,
   pagesCount : number,
   products : any[],
   loading:boolean
}

const MAX_PER_PAGE = 16;

class Shop extends Component<any, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      index : 0,
      productsCount : 0,
      page : 1,
      pagesCount : 0,
      products : [],
      loading:false
    };
  }

  componentDidMount(): void {
  
    this.loadMore();

  }

  loadMore()
  {
    this.setState({loading : true})
    var pathname = this.props.location.pathname;
    var id = pathname.split("/")[3];

    if(pathname.includes("rubriques"))
    {
        this.getRubricProducts(id)
    }
    else if(pathname.includes("categories"))
    {
        this.getCategoryProducts(id)
    }

  }

  getRubricProducts(id:number)
  {
    contentService.getRubricProducts(id,this.state.page)
    .then((d:any)=>{

        var products = d;
        var p = [...this.state.products, ...products]
        p = Array.from(
            new Set(p.map((item) => item.id)),
            (id) => p.find((obj) => obj.id === id)
          );
        this.setState({products : p})
        if(products.length)
        this.setState({page : this.state.page + 1})
        this.setState({loading : false})

    })
    .catch(e=>{
      console.log(e)
      this.setState({loading : false})

    })
  }
 
  getCategoryProducts(id:any)
  {
    
    contentService.getCategoryProducts(id,this.state.page)
    .then((d:any)=>{

        var products = d;
        var p = [...this.state.products, ...products]
        p = Array.from(
            new Set(p.map((item) => item.id)),
            (id) => p.find((obj) => obj.id === id)
          );
        this.setState({products : p})
        if(products.length)
        this.setState({page : this.state.page + 1})
        this.setState({loading : false})

    })
    .catch(e=>{
      console.log(e)
      this.setState({loading : false})
    })
  }
 

  gotoShopdetail = (product:any)=>
  {
    this.props.mainActions.setProduct(product);
    this.props.navigate("/shopdetail/"+product.id)
  }


   render(): React.ReactNode {
     return(

<div className="container-fluid">
        <div className="row px-xl-5">
            {/* <!-- Shop Sidebar Start --> */}
            <div className="col-lg-1 col-md-1">
                {/* <!-- Price Start --> */}
                {/* <h5 className="section-title position-relative text-uppercase mb-3"><span className="bg-secondary pr-3">Filtrer par date</span></h5>
                <div className="bg-light p-4 mb-30">
                    <form>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" checked id="price-all" />
                            <label className="custom-control-label" htmlFor="price-all">Tous</label>
                            <span className="badge border font-weight-normal">1000</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="price-1" />
                            <label className="custom-control-label" htmlFor="price-1">Plus récents</label>
                            <span className="badge border font-weight-normal">150</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="price-2" />
                            <label className="custom-control-label" htmlFor="price-2">Plus anciens</label>
                            <span className="badge border font-weight-normal">295</span>
                        </div>
                         
                    </form>
                </div> */}
                {/* <!-- Price End --> */}
                
                {/* <!-- Color Start --> */}
                {/* <h5 className="section-title position-relative text-uppercase mb-3"><span className="bg-secondary pr-3">Filter by color</span></h5>
                <div className="bg-light p-4 mb-30">
                    <form>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" checked id="color-all" />
                            <label className="custom-control-label" htmlFor="price-all">All Color</label>
                            <span className="badge border font-weight-normal">1000</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-1" />
                            <label className="custom-control-label" htmlFor="color-1">Black</label>
                            <span className="badge border font-weight-normal">150</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-2" />
                            <label className="custom-control-label" htmlFor="color-2">White</label>
                            <span className="badge border font-weight-normal">295</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-3" />
                            <label className="custom-control-label" htmlFor="color-3">Red</label>
                            <span className="badge border font-weight-normal">246</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-4" />
                            <label className="custom-control-label" htmlFor="color-4">Blue</label>
                            <span className="badge border font-weight-normal">145</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between">
                            <input type="checkbox" className="custom-control-input" id="color-5" />
                            <label className="custom-control-label" htmlFor="color-5">Green</label>
                            <span className="badge border font-weight-normal">168</span>
                        </div>
                    </form>
                </div> */}
                {/* <!-- Color End --> */}

                {/* <!-- Size Start --> */}
                {/* <h5 className="section-title position-relative text-uppercase mb-3"><span className="bg-secondary pr-3">Filter by size</span></h5>
                <div className="bg-light p-4 mb-30">
                    <form>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" checked id="size-all" />
                            <label className="custom-control-label" htmlFor="size-all">All Size</label>
                            <span className="badge border font-weight-normal">1000</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-1" />
                            <label className="custom-control-label" htmlFor="size-1">XS</label>
                            <span className="badge border font-weight-normal">150</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-2" />
                            <label className="custom-control-label" htmlFor="size-2">S</label>
                            <span className="badge border font-weight-normal">295</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-3" />
                            <label className="custom-control-label" htmlFor="size-3">M</label>
                            <span className="badge border font-weight-normal">246</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-4" />
                            <label className="custom-control-label" htmlFor="size-4">L</label>
                            <span className="badge border font-weight-normal">145</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between">
                            <input type="checkbox" className="custom-control-input" id="size-5" />
                            <label className="custom-control-label" htmlFor="size-5">XL</label>
                            <span className="badge border font-weight-normal">168</span>
                        </div>
                    </form>
                </div> */}
                {/* <!-- Size End --> */}
            </div>
            {/* <!-- Shop Sidebar End --> */}


            {/* <!-- Shop Product Start --> */}
            <div className="col-lg-10 col-md-10">
                <div className="row pb-3">
                    <div className="col-12 pb-1">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <button className="btn btn-sm btn-light"><i className="fa fa-th-large"></i></button>
                                <button className="btn btn-sm btn-light ml-2"><i className="fa fa-bars"></i></button>
                            </div>
                            <div className="ml-2">
                                {/* <div className="btn-group">
                                    <button type="button" className="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">Sorting</button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#">Latest</a>
                                        <a className="dropdown-item" href="#">Popularity</a>
                                        <a className="dropdown-item" href="#">Best Rating</a>
                                    </div>
                                </div> */}
                                {/* <div className="btn-group ml-2">
                                    <button type="button" className="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">Showing</button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#">10</a>
                                        <a className="dropdown-item" href="#">20</a>
                                        <a className="dropdown-item" href="#">30</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    
                    {this.state.products.map((product:any, index:number)=>{
                        return <div key={index} className="col-lg-3 col-md-6 col-sm-6 pb-1">
                        <div className="product-item bg-light mb-4">
                            <div className="product-img position-relative overflow-hidden">
                                <img className="img-fluid w-100" onClick={()=>this.gotoShopdetail(product)} src={`${baseUrl}${product.images[0].url}`} alt="" />
                            </div>
                            <div className="text-center py-4">
                                <a className="h6 text-decoration-none text-truncate" href="">{product.titre}</a>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    {/* <h5>$123.00</h5><h6 className="text-muted ml-2"><del>$123.00</del></h6> */}
                                </div>
                                <div className="d-flex align-items-center justify-content-center mb-1">
                                    {Array(product.note||0).fill('-').map((n:any, ind:number)=>{
                                        return <small key={ind} className="fa fa-star text-primary mr-1"></small>
                                        })
                                    }
                                    {Array((5-(product.note||0))).fill('-').map((n:any, ind:number)=>{
                                        return <small key={ind} className="fa fa-star text-default mr-1"></small>
                                        })
                                    }
                                    <small>({product.note}/5)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    })
                    
                    }
                    {!this.state.products.length &&
                        <div>
                            Aucun article disponible
                        </div>
                    }
                 
                    <div className="col-12">
                        <nav>
                          <ul className="pagination justify-content-center">
                            {!this.state.loading && <li onClick={()=>this.loadMore()} className="page-item active" style={{cursor:'pointer'}}><a className="page-link" >Charger plus</a></li>}
                            {this.state.loading && <li className="page-item" >Chargement...</li>}
                          </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Shop Product End --> */}
        </div>
    </div>)
    }
    }


const mapStateToProps = (state:any) => {
    return {
      mainState: state.mainReducer
    };
  };
  
  const mapDispatchToProps = (dispatch:any) => {
    return {
      mainActions: bindActionCreators(mainActions, dispatch)
    };
  };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Shop));
  