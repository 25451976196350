import React, { Component, useState } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import Shop from '../components/Shop';
import contentService from '../services/content.service';


interface State {
   index : number,
   contacts : any
}


class ContactPage extends Component<any, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      index : 0,
      contacts : null
    };
  }

  componentDidMount(): void {
       this.getContacts();
  }
   

  getContacts()
  {
    contentService.getContacts()
    .then((d:any)=>{
 
        var contacts = d[0];
        this.setState({contacts : contacts})
    })
    .catch(e=>{
      console.log(e)
    })
  }

 
   render(): React.ReactNode {
     return(
        <div className="cmd-page">
            <Header />
            <Breadcrumb breadcrumb="Contacts" />

            <div className="container-fluid">
              <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="bg-secondary pr-3">Nous Contacter</span></h2>
              <div className="row px-xl-5">
                  <div className="col-lg-5 mb-5">
                      <div className="contact-form bg-light p-30">
                          <div id="success"></div>
                          <form name="sentMessage" id="contactForm" noValidate={true}>
                              <div className="control-group">
                                  <input type="text" className="form-control" id="name" placeholder="Votre nom"
                                      required={true} data-validation-required-message="Saisissez votre nom" />
                                  <p className="help-block text-danger"></p>
                              </div>
                              <div className="control-group">
                                  <input type="email" className="form-control" id="email" placeholder="Votre email"
                                      required={true} data-validation-required-message="Saisissez votre email" />
                                  <p className="help-block text-danger"></p>
                              </div>
                              <div className="control-group">
                                  <input type="text" className="form-control" id="subject" placeholder="Sujet"
                                      required={true} data-validation-required-message="Saisissez un sujet" />
                                  <p className="help-block text-danger"></p>
                              </div>
                              <div className="control-group">
                                  <textarea className="form-control" rows={8} id="message" placeholder="Message"
                                      required={true}
                                      data-validation-required-message="Saisissez votre message"></textarea>
                                  <p className="help-block text-danger"></p>
                              </div>
                              <div>
                                  <button className="btn btn-primary py-2 px-4" type="submit" id="sendMessageButton">Envoyer</button>
                              </div>
                          </form>
                      </div>
                  </div>
                  <div className="col-lg-7 mb-5">
                      <div className="bg-light p-30 mb-10">
                      <iframe width="100%" height="350"  style={{border:'solid 1px gray'}} src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Carrefour%20Mame%20Ndakhete+(CMD)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                      </div>
                      <div className="bg-light p-30 mb-3" style={{paddingTop:0}}>
                          <p className="mb-2"><i className="fa fa-map-marker-alt text-primary mr-3"></i>{this.state.contacts?.adresse}</p>
                          <p className="mb-2"><i className="fa fa-envelope text-primary mr-3"></i>{this.state.contacts?.email1}</p>
                          <p className="mb-2"><i className="fa fa-phone-alt text-primary mr-3"></i>{this.state.contacts?.tel1}</p>
                      </div>
                  </div>
              </div>
          </div>

            <Footer />
        </div>
    )
  };
};

export default ContactPage;
