import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import ShopPage from './pages/ShopPage';
import Hero from './components/Hero';
import NotFoundPage from './pages/NotFoundPage';
import ShopDetail from './components/ShopDetail';
import ShopDetailPage from './pages/ShopDetail';
import ContactPage from './pages/ContactPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/shop/rubriques/:id" element={<ShopPage />} />
          <Route path="/shop/categories/:id" element={<ShopPage />} />
          <Route path="/shopdetail/:id" element={<ShopDetailPage />} />
          <Route path="/contact" element={<ContactPage />} />

          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
