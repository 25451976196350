import { api } from "./api.config";

const contentService = {
 
  getHeroes,
  getBanners,
  getCategories,
  getCategoryProducts,
  getRubricProducts,
  getCategoryProductsCount,
  getRubricProductsCount,
  getRubrics,
  getFeaturedProducts,
  getNewestProducts,
  getContacts,


 
};

const resource = "";

const MAX_PER_PAGE = 16;

/**
 * --- method
 *
 * @returns
 */
 function getHeroes() {
  return api.getData(resource + "/heroes");
}
 
/**
 * --- method
 *
 * @returns
 */
function getBanners() {
  return api.getData(resource + "/bannieres");
}
 
/**
 * --- method
 *
 * @returns
 */
function getContacts() {
  return api.getData(resource + "/contacts");
}
 

/**
 * --- method
 *
 * @returns
 */
 function getCategories() {
  return api.getData(resource + "/categories");
}
 


/**
 * --- method
 *
 * @returns
 */
function getRubrics() {
  return api.getData(resource + "/rubriques");
}
 

/**
 * --- method
 *
 * @returns
 */
 function getFeaturedProducts() {
  let filter = `?enVedette_eq=true`;
  return api.getData(resource + "/produits"+filter+"&_limit=8");
}


/**
 * --- method
 *
 * @param id
 * @returns
 */
function getCategoryProducts(id:any, page:number) {
  var start = (page-1) * MAX_PER_PAGE;
  return api.getData(resource + "/produits?_start="+start+"&_limit="+MAX_PER_PAGE+"&categorie.id="+id);
}

/**
 * --- method
 * @param id
 * @returns
 */
function getRubricProducts(id:any, page:number) {
  var start = (page-1) * MAX_PER_PAGE;
  return api.getData(resource + "/produits?_start="+start+"&_limit="+MAX_PER_PAGE+"&rubrique.id="+id);
}





/**
 * --- method
 *
 * @param id
 * @returns
 */
function getCategoryProductsCount(id:any) {
  return api.getData(resource + "/categories/count?_where[categorie]="+id);
}


/**
 * --- method
 * @param id
 * @returns
 */
function getRubricProductsCount(id:any) {
  return api.getData(resource + "/rubriques/count?_where[rubrique]="+id);
}



/**
 * --- method
 *
 * @returns
 */
 function getNewestProducts() {
  return api.getData(resource + "/produits?_sort=published_at:desc&_limit=8");
}

 

export default contentService;
