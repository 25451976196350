interface MainState {
    value: number;
    product : any;
  }
  
  const initialState: MainState = {
    value: 0,
    product : null
  };
  
  export enum MainActionTypes {
    SET_PRODUCT = "SET_PRODUCT",
  }
  
  
  
  const mainReducer = (state = initialState, action: any): MainState => {
    switch (action.type) {
     
      case MainActionTypes.SET_PRODUCT:
        return { ...state, product: action.payload };
      default:
        return state;
    }
  };
  
  export default mainReducer;
  