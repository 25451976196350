import React, { Component, useState } from 'react';


interface State {
   index : number
}


class Breadcrumb extends Component<any, State> {

  constructor(props: {}) {
    super(props);
    this.state = {
      index : 0
    };
  }

 
   render(): React.ReactNode {
     return(
        <div className="container-fluid">
            <div className="row px-xl-5">
                <div className="col-12">
                    <nav className="breadcrumb bg-light mb-30">
                        <a className="breadcrumb-item text-dark" href="#">Accueil</a>
                        <a className="breadcrumb-item text-dark" href="#">{this.props.breadcrumb}</a>
                    </nav>
                </div>
            </div>
        </div>
    )
  };
};

export default Breadcrumb;
