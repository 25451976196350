import React, { Component, useState } from 'react';
import contentService from '../services/content.service';
import { baseUrl } from '../services/apiUrl';
import { withRouter } from './WithRouterProps';
import { connect } from 'react-redux';
import { bindActionCreators} from 'redux';
import * as mainActions from '../redux/main/mainActions'
import { Spinner } from 'react-bootstrap';


interface State {
   index : number,
   products : any[]
}


class FeaturedProducts extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      index : 0,
      products : [],
      loaded:false
    };
  }

  
  componentDidMount() {

    this.getFeaturedProducts();

  }

  getFeaturedProducts()
  {
    contentService.getFeaturedProducts()
    .then((d:any)=>{

        var products = d;
        this.setState({products : products, loaded : true})
    })
    .catch((e:any)=>{
      this.setState({loaded : true})
      console.log(e)
    })
  }


  gotoShopdetail = (product:any)=>
  {
    this.props.mainActions.setProduct(product);
    this.props.navigate("/shopdetail/"+product.id)
  }
 
   render(): React.ReactNode {
     return(
        <div className="container-fluid pt-5 pb-3">
        <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="bg-secondary pr-3">PRODUITS VEDETTES</span></h2>
        
        
        
          {!this.state.loaded &&

            <div className='data-preloader'>
                <Spinner animation="border" role="status"></Spinner>
                <span>Chargement...</span>
            </div>
          }
          {this.state.loaded &&<div className="row px-xl-5">
            
            {this.state.products.map((item:any, index:number)=>{

                return <div key={index} className="col-lg-3 col-md-4 col-sm-6 pb-1" >
                    <div className="product-item bg-light mb-4">
                        <div className="product-img position-relative overflow-hidden">
                            <img onClick={()=>this.gotoShopdetail(item)} className="img-fluid w-100" src={`${baseUrl}${item.images[0].url}`} alt="" />
                        </div>
                        <div className="text-center py-4">
                            <a className="h6 text-decoration-none text-truncate" href="">{item.titre}</a>
                            <div className="d-flex align-items-center justify-content-center mt-2">
                                {/* <h5>$123.00</h5><h6 className="text-muted ml-2"><del>$123.00</del></h6> */}
                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-1">
                                {Array(item.note||0).fill('-').map((n:any, ind:number)=>{
                                    return <small key={ind} className="fa fa-star text-primary mr-1"></small>
                                    })
                                }
                                {Array((5-(item.note||0))).fill('-').map((n:any, ind:number)=>{
                                    return <small key={ind} className="fa fa-star text-default mr-1"></small>
                                    })
                                }
                                <small>({item.note}/5)</small>
                            </div>
                        </div>
                    </div>
                </div>
            })
            }
        </div>}
    </div>
    )
  };
};
 
const mapStateToProps = (state:any) => {
  return {
    mainState: state.mainReducer
  };
};

const mapDispatchToProps = (dispatch:any) => {
  return {
    mainActions: bindActionCreators(mainActions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeaturedProducts));
