import React, { Component, useState } from 'react';
import { baseUrl } from '../services/apiUrl';
import { Spinner } from 'react-bootstrap';


interface State {
   index : number
}


class Promo extends Component<any, State> {

  constructor(props:any) {
    super(props);
    this.state = {
      index : 0
    };
  }

 
   render(): React.ReactNode {
     return(
        <div className="container-fluid pt-5 pb-3">
        <div className="row px-xl-5">
            
            {!this.props.loaded &&
              <div className='data-preloader'>
                <Spinner animation="border" role="status"></Spinner>
                <span>Chargement...</span>
              </div>
            }
            {this.props.banners.map((item:any,index:number)=>{
                
                return <div key={index} className="col-md-6">
                  <div className="product-offer mb-30" style={{height: 300}}>
                      <img className="img-fluid" src={`${baseUrl}${item.image.url}`} alt="" />
                      <div className="offer-text">
                          {/* <h6 className="text-white text-uppercase">Save 20%</h6> */}
                          <h3 className="text-white mb-3">{item.titre}</h3>
                          {/* <a href="" className="btn btn-primary">Shop Now</a> */}
                      </div>
                  </div>
                </div>
              })
            }
             
        </div>
    </div>
    )
  };
};

export default Promo;
