
export const baseUrl = "https://admin.cmdmultiservice.com";
// export const baseUrl = "http://localhost:1337";
const apiUrl = baseUrl + "/";


const appUrls = {
  baseUrl,
  apiUrl
}

export default appUrls;
