import React, { Component, useState } from 'react';
import contentService from '../services/content.service';


interface State {
   index : number,
   contacts : any
}


class Footer extends Component<any, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      index : 0,
      contacts : null
    };
  }

 componentDidMount(): void {
     
    this.getContacts();
 }

 getContacts()
 {
   contentService.getContacts()
   .then((d:any)=>{

       var contacts = d[0];
       this.setState({contacts : contacts})
   })
   .catch(e=>{
     console.log(e)
   })
 }

   render(): React.ReactNode {
     return(
    <div>
       
       <div className="container-fluid bg-dark text-secondary mt-5 pt-5">
          <div className="row px-xl-5 pt-5">
              <div className="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
                  <h5 className="text-secondary text-uppercase mb-4">CMD MULTISERVICE</h5>
                  <p className="mb-4">Slogan de l'entreprise</p>
                  <p className="mb-2"><i className="fa fa-map-marker-alt text-primary mr-3"></i>{this.state.contacts?.adresse}</p>
                  <p className="mb-2"><i className="fa fa-envelope text-primary mr-3"></i>{this.state.contacts?.email1}</p>
                  <p className="mb-1"><i className="fa fa-phone-alt text-primary mr-3"></i>{this.state.contacts?.tel1}</p>
                  <p className="mb-0"><i className="fa fa-phone-alt text-primary mr-3"></i>{this.state.contacts?.tel2}</p>
              </div>
              <div className="col-lg-8 col-md-12">
                  <div className="row">
                      <div className="col-md-4 mb-5">
                          <h5 className="text-secondary text-uppercase mb-4"></h5>
                          <div className="d-flex flex-column justify-content-start">
                              {/* <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Home</a>
                              <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Our Shop</a>
                              <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Shop Detail</a>
                              <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Shopping Cart</a>
                              <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Checkout</a>
                              <a className="text-secondary" href="#"><i className="fa fa-angle-right mr-2"></i>Contact Us</a> */}
                          </div>
                      </div>
                      <div className="col-md-4 mb-5">
                          <h5 className="text-secondary text-uppercase mb-4"></h5>
                          <div className="d-flex flex-column justify-content-start">
                              {/* <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Home</a>
                              <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Our Shop</a>
                              <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Shop Detail</a>
                              <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Shopping Cart</a>
                              <a className="text-secondary mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Checkout</a>
                              <a className="text-secondary" href="#"><i className="fa fa-angle-right mr-2"></i>Contact Us</a> */}
                          </div>
                      </div>
                      <div className="col-md-4 mb-5">
                          <h5 className="text-secondary text-uppercase mb-4">Contacts</h5>
                          <p>Nous sommes disponible à tout moment pour répondre à vos requêtes</p>
                          <form action="">
                              <div className="input-group">
                                  {/* <input type="text" className="form-control" placeholder="Your Email Address" />
                                  <div className="input-group-append">
                                      <button className="btn btn-primary">Sign Up</button>
                                  </div> */}
                              </div>
                          </form>
                          <h6 className="text-secondary text-uppercase mt-4 mb-3">Nous suivre</h6>
                          <div className="d-flex">
                              {/* <a className="btn btn-primary btn-square mr-2" href="#"><i className="fab fa-twitter"></i></a> */}
                              <a className="btn btn-primary btn-square mr-2" href="#"><i className="fab fa-facebook-f"></i></a>
                              <a className="btn btn-primary btn-square mr-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                              {/* <a className="btn btn-primary btn-square" href="#"><i className="fab fa-instagram"></i></a> */}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="row border-top mx-xl-5 py-4" style={{borderColor: 'rgba(256, 256, 256, .1) !important'}}>
              <div className="col-md-6 px-xl-0">
                  <p className="mb-md-0 text-center text-md-left text-secondary">
                      &copy; <a className="text-primary" href="#">cmdmultiservice.com</a>. Tous droits réservés
                  </p>
              </div>
              <div className="col-md-6 px-xl-0 text-center text-md-right">
                  <img className="img-fluid" src="" alt="" />
              </div>
          </div>
      </div>
     </div>
    )
  };
};

export default Footer;
