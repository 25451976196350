import React, { Component, useState } from 'react';


interface State {
   index : number
}


class Partners extends Component<{}, State> {

  constructor(props: {}) {
    super(props);
    this.state = {
      index : 0
    };
  }

 
   render(): React.ReactNode {
     return(
      <div className="container-fluid py-5">
        <div className="row px-xl-5">
            <div className="col">
                <div className="owl-carousel vendor-carousel">
                    <div className="bg-light p-4">
                        <img src="img/vendor-1.jpg" alt="" />
                    </div>
                    <div className="bg-light p-4">
                        <img src="img/vendor-2.jpg" alt="" />
                    </div>
                    <div className="bg-light p-4">
                        <img src="img/vendor-3.jpg" alt="" />
                    </div>
                    <div className="bg-light p-4">
                        <img src="img/vendor-4.jpg" alt="" />
                    </div>
                    <div className="bg-light p-4">
                        <img src="img/vendor-5.jpg" alt="" />
                    </div>
                    <div className="bg-light p-4">
                        <img src="img/vendor-6.jpg" alt="" />
                    </div>
                    <div className="bg-light p-4">
                        <img src="img/vendor-7.jpg" alt="" />
                    </div>
                    <div className="bg-light p-4">
                        <img src="img/vendor-8.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  };
};

export default Partners;
