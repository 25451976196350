import React, { Component, useState } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Features from '../components/Features';
import CategoriesCards from '../components/CategoriesCards';
import FeaturedProducts from '../components/FeaturedProducts';
import Promo from '../components/Promo';
import RecentProducts from '../components/RecentProducts';
import Partners from '../components/Partners';
import contentService from '../services/content.service';


interface State {
   index : number,
   banners : any[],
   categories : any[],
   contacts : any,
}


class HomePage extends Component<any, any> {

  constructor(props: {}) {
    super(props);
    this.state = {
      index : 0,
      banners : [],
      loadedPromo : false,
      categories : [],
      loadedCategories : false,
      contacts : {}
    };
  }


  componentDidMount() {

    this.getContacts();
    this.getBanners();
    this.getCategories();

  }

  getBanners()
  {
    contentService.getBanners()
    .then((d:any)=>{

        var banners = d;
        this.setState({banners : banners, loadedPromo : true})
    })
    .catch(e=>{
      this.setState({loadedPromo : true})
      console.log(e)
    })
  }


  getCategories()
  {
    contentService.getCategories()
    .then((d:any)=>{

        var categories = d;
        this.setState({categories : categories, loadedCategories : true})
    })
    .catch(e=>{
      this.setState({loadedCategories : true})
      console.log(e)
    })
  }
 
  getContacts()
  {
    contentService.getContacts()
    .then((d:any)=>{

        var contacts = d[0];
        this.setState({contacts : contacts})
    })
    .catch(e=>{
      console.log(e)
    })
  }
 
   render(): React.ReactNode {
     return(
        <div className="cmd-page">
            <Header contacts={this.state.contacts} />
            <Hero banners={this.state.banners} />
            <Features />
            <CategoriesCards loaded={this.state.loadedCategories} categories={this.state.categories} />
            <FeaturedProducts />
            <Promo loaded={this.state.loadedPromo}  banners={this.state.banners} />
            <RecentProducts />
            <Partners />
            <Footer />
        </div>
    )
  };
};

export default HomePage;
