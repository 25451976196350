import React, { Component, useState } from 'react';
import contentService from '../services/content.service';


interface State {
   index : number,
   categories : any[],
   rubrics : any[],
   contacts : any
}


class Header extends Component<any, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      index : 0,
      categories : [],
      rubrics : [],
      contacts : null
    };
  }


  componentDidMount(): void {
    this.getContacts()
    this.getCategories()
    this.getRubrics()
  }

  getCategories()
  {
    contentService.getCategories()
    .then((d:any)=>{

        var categories = d;
        this.setState({categories : categories})
    })
    .catch(e=>{
      console.log(e)
    })
  }
 

  getRubrics()
  {
    contentService.getRubrics()
    .then((d:any)=>{

        var rubrics = d;
        this.setState({rubrics : rubrics})
    })
    .catch(e=>{
      console.log(e)
    })
  }
 

  getContacts()
  {
    contentService.getContacts()
    .then((d:any)=>{
 
        var contacts = d[0];
        this.setState({contacts : contacts})
    })
    .catch(e=>{
      console.log(e)
    })
  }

 
   render(): React.ReactNode {
     return(
      <div>
      {/* <!-- Topbar Start --> */}
      <div className="container-fluid">
        <div className="row bg-secondary py-1 px-xl-5">
          <div className="col-lg-6 d-none d-lg-block">
            {/* <div className="d-inline-flex align-items-center h-100">
              <a className="text-body mr-3" href="">
                About
              </a>
              <a className="text-body mr-3" href="">
                Contact
              </a>
              <a className="text-body mr-3" href="">
                Help
              </a>
              <a className="text-body mr-3" href="">
                FAQs
              </a>
            </div> */}
          </div>
          <div className="col-lg-6 text-center text-lg-right">
            {/* <div className="d-inline-flex align-items-center">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-sm btn-light dropdown-toggle"
                  data-toggle="dropdown"
                >
                  My Account
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item" type="button">
                    Sign in
                  </button>
                  <button className="dropdown-item" type="button">
                    Sign up
                  </button>
                </div>
              </div>
              <div className="btn-group mx-2">
                <button
                  type="button"
                  className="btn btn-sm btn-light dropdown-toggle"
                  data-toggle="dropdown"
                >
                  USD
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item" type="button">
                    EUR
                  </button>
                  <button className="dropdown-item" type="button">
                    GBP
                  </button>
                  <button className="dropdown-item" type="button">
                    CAD
                  </button>
                </div>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-sm btn-light dropdown-toggle"
                  data-toggle="dropdown"
                >
                  EN
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item" type="button">
                    FR
                  </button>
                  <button className="dropdown-item" type="button">
                    AR
                  </button>
                  <button className="dropdown-item" type="button">
                    RU
                  </button>
                </div>
              </div>
            </div> */}
            {/* <div className="d-inline-flex align-items-center d-block d-lg-none">
              <a href="" className="btn px-0 ml-2">
                <i className="fas fa-heart text-dark"></i>
                <span
                  className="badge text-dark border border-dark rounded-circle"
                  style={{ paddingBottom: 2 }}
                >
                  0
                </span>
              </a>
              <a href="" className="btn px-0 ml-2">
                <i className="fas fa-shopping-cart text-dark"></i>
                <span
                  className="badge text-dark border border-dark rounded-circle"
                  style={{ paddingBottom: 2 }}
                >
                  0
                </span>
              </a>
            </div> */}
          </div>
        </div>
        <div className="row align-items-center bg-light py-3 px-xl-5 d-none d-lg-flex">
          <div className="col-lg-4">
            <a href="/" className="text-decoration-none">
              <span className="h1 text-uppercase text-primary bg-dark px-2">
                CMD
              </span>
              <span className="h2 text-uppercase text-dark bg-primary px-2 ml-n1">
                Multiservices
              </span>
            </a>
          </div>
          <div className="col-lg-4 col-6 text-left">
            {/* <form action="">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Rechercher un produit..."
                />
                <div className="input-group-append">
                  <span className="input-group-text bg-transparent text-primary">
                    <i className="fa fa-search"></i>
                  </span>
                </div>
              </div>
            </form> */}
          </div>
          <div className="col-lg-4 col-6 text-right">
            <p className="m-0">Service Commercial</p>
            <h5 className="m-0">{this.state.contacts?.tel1}</h5>
          </div>
        </div>
      </div>
      {/* <!-- Topbar End --> */}

      {/* <!-- Navbar Start --> */}
      <div className="container-fluid bg-dark mb-30">
        <div className="row px-xl-5">
          <div className="col-lg-3 d-none d-lg-block">
            <a
              className="btn d-flex align-items-center justify-content-between bg-primary w-100"
              data-toggle="collapse"
              href="#navbar-vertical"
              style={{
                height: 65,
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <h6 className="text-dark m-0">
                <i className="fa fa-bars mr-2"></i>Categories
              </h6>
              <i className="fa fa-angle-down text-dark"></i>
            </a>
            <nav
              className="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light"
              id="navbar-vertical"
              style={{ width: "100%", zIndex: 999, boxShadow: '5px 3px 10px #00000070'}}
            >
              <div className="navbar-nav w-100">
                {this.state.categories.map((c,i)=>{
                  return <a key={i} href={`/shop/categories/${c.id}`} className="nav-item nav-link">
                            {c.titre}
                          </a>
                })}
              </div>
            </nav>
          </div>
          <div className="col-lg-9">
            <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0">
              <a href="/" className="text-decoration-none d-block d-lg-none">
                <span className="h1 text-uppercase text-dark bg-light px-2">
                  CMD
                </span>
                <span className="h1 text-uppercase text-light bg-primary px-2 ml-n1">
                  Multiservices
                </span>
              </a>
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-between"
                id="navbarCollapse"
              >
                <div className="navbar-nav mr-auto py-0">
                  <a href="index.html" className="nav-item nav-link active">
                    Home
                  </a>
                  <a href="/shop/rubriques/2" className="nav-item nav-link">
                    Papeterie
                  </a>
                  <a href="/shop/rubriques/1" className="nav-item nav-link">
                    Electroménager
                  </a>
                  <div className="nav-item dropdown">
                    <a
                      href="#"
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Rubriques <i className="fa fa-angle-down mt-1"></i>
                    </a>
                    <div className="dropdown-menu bg-primary rounded-0 border-0 m-0">
                     {this.state.rubrics.map((r,i)=>{
                      return <a key={i} className="dropdown-item" href={`/shop/rubriques/${r.id}`} >
                        {r.titre}
                      </a>
                     })}
                       
                    </div>
                  </div>
                  <a href="/contact" className="nav-item nav-link">
                    Contact
                  </a>
                </div>
                {/* <div className="navbar-nav ml-auto py-0 d-none d-lg-block">
                  <a href="" className="btn px-0">
                    <i className="fas fa-heart text-primary"></i>
                    <span
                      className="badge text-secondary border border-secondary rounded-circle"
                      style={{ paddingBottom: 2 }}
                    >
                      0
                    </span>
                  </a>
                  <a href="" className="btn px-0 ml-3">
                    <i className="fas fa-shopping-cart text-primary"></i>
                    <span
                      className="badge text-secondary border border-secondary rounded-circle"
                      style={{ paddingBottom: 2 }}
                    >
                      0
                    </span>
                  </a>
                </div> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* <!-- Navbar End --> */}
    </div>
     )
   }


}



export default Header;
